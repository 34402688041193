$text-blue: #2987cd;

.text-blue {
  color: $text-blue;
}

.info.circle.icon {
  margin-top: -0.5em;
}

.inline-input {
  padding-right: 0px !important;
  width: 100%;
}

.visible.transition {
  display: flex !important;
  flex-direction: column;
}

.dimmed.dimmable > .ui.visible.dimmer,
.ui.active.dimmer {
  display: -ms-flexbox;
  display: flex !important;
  flex-direction: column;
  opacity: 1;
}

.ui.card .content {
  padding-top: 0.2em;
  padding-bottom: 0.2em;
}

.extra .content {
  padding-top: 0.2em;
  padding-bottom: 0.2em;
}

.bgp-segment {
  margin: 0 auto !important;
}

#playerHeader {
  background: #39cc18;
}