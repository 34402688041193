.container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.interactionSection {
    width: 100%;
}

.profileSection {
    margin-top: 2em;
}

.meetingSection {
    margin: 2em 0;
}