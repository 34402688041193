body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #D2CFCF;
  background-size: cover;
  text-align: center;
  min-height: 100vh;
  min-width: 100%;
  overflow-y: scroll;
}

html {
  min-height:100vh;
  min-width: 100%;
  margin: 0;
}