.circle {
  margin: 0 auto;
  color: black;
  border-radius: 50%;
  background-color: white;
  text-align: center;
  vertical-align: middle;
  height: 10.5em;
  width: 10.5em;
  z-index: 1;
}

.dimicon {
  position: relative;
  top: .37em;
  margin: 0 auto !important;
  font-size: 6em !important;
  z-index: 2;
}

.dimloader,
.dimloader:after {
  margin: 0 auto;
  border-radius: 50%;
  width: 10em;
  height: 10em;
  z-index: 2;
}
.dimloader {
  top: 2.3em;
  background-color: white;
  z-index: 2;
  margin: 0 auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(231, 231, 231, 0.9);
  border-right: 1.1em solid rgba(231, 231, 231, 0.9);
  border-bottom: 1.1em solid rgba(231, 231, 231, 0.9);
  border-left: 1.1em solid #767676;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
